import { Flag } from '../enum/flag';

export class Report {
  flags: Flag;
  userId: string;
  text?: string;
  created: Date;

  constructor(json: any) {
    this.flags = json.flags as Flag;
    this.userId = json.userId;
    this.text = json.text ? json.text : undefined;
    this.created = json.created;
  }
}

export class Flags {
  value: Flag;
  text?: string;
  reports: Report[];

  constructor(json: any) {
    this.value = json.value ? (json.value as Flag) : Flag.None;
    this.text = json.text ? json.text : undefined;
    this.reports = json.reports ? json.reports.map((json: any) => new Report(json)) : [];
  }
}
