export class Device {
  handle: string;
  appId: string;
  build: number;
  os: string;
  osVer: string;

  constructor(json: any) {
    this.handle = json.handle;
    this.appId = json.appId;
    this.build = json.build;
    this.os = json.os;
    this.osVer = json.osVer;
  }
}
