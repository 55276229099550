import { Identifiable } from '../interface/identifiable';
import { Deletable } from '../interface/deletable';
import { Factory } from '../interface/factory';

export class Room implements Deletable, Identifiable {
  id: string;
  deleted?: boolean;
  deletedDbFlag: 0 | 1; // IndexDB can't index by boolean so we use a number to enable indexing by hide.

  tenantUnitId: string;
  buildingId: string;
  levelIds: string[];
  apartmentId?: string;

  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = this.deleted ? 1 : 0;

    this.tenantUnitId = json.tenantUnitId;
    this.buildingId = json.buildingId;
    this.levelIds = json.levelIds;
    this.apartmentId = json.apartmentId;

    this.modified = json.modified;
  }

  public static getFactory(): Factory<Room> {
    return new (class implements Factory<Room> {
      make(json: any): Room {
        return new Room(json);
      }
      getTableName(): string {
        return 'rooms';
      }
    })();
  }

  static getUrl(tenantUnitId: string, buildingId: string, roomId?: string): string;
  static getUrl(tenantUnitId: string, buildingId: string, roomId: string): string {
    return '/tenant_units/' + tenantUnitId + '/buildings/' + buildingId + '/rooms' + (roomId ? '/' + roomId : '');
  }
}
