import { environment } from '../../../environments/environment';
import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { I18nString } from './i18nstring';
import { Role } from './role';

// ResidentialInformationBox
export class R20nBox implements Identifiable {
  id: string; // NOTE: Used for deleting.
  texts: I18nString[];
  images: string[];
  requiredRoles: Role[];

  constructor(json: any) {
    this.id = json.id;
    this.texts = json.texts ? json.texts.map((json: any) => new I18nString(json)) : [];
    this.images = json.images ? json.images : [];
    const roleFactory: Factory<Role> = Role.getFactory();
    this.requiredRoles = json.requiredRoles ? json.requiredRoles.map((json: any) => roleFactory.make(json)) : [];
  }

  getImageUrl(index: number = this.images.length - 1): string | undefined {
    return this.images[index] ? environment.blobUrl + '/images/' + this.images[index] : undefined;
  }
}
