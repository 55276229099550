<!-- Header -->
<div class="modal-header border-0">
  <div class="title-container">
    <h4 class="modal-title">Glömt lösenord</h4>
  </div>
  <button type="button" class="close border-0" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<!-- Body -->
<div class="modal-body">
  <form class="voz-form tu-fault-report-form" [formGroup]="forgotPasswordForm" (ngSubmit)="submitForgotPassword()">
    <!-- Email  -->
    <div class="form-group">
      <div class="label-container">
        <label for="email">Email</label>
      </div>
      <input
        id="emailField"
        name="email"
        class="input-box"
        placeholder="Ange email"
        type="string"
        formControlName="email"
      />
    </div>
    <div class="text-muted text-sm" style="margin-top: 5px">
      Återställningsinformation kommer skickas till den angivna e-postadressen om den finns i vårat system.
    </div>

    <!-- Buttons -->
    <div class="btn-container">
      <!-- Submit -->
      <button
        type="submit"
        class="btn btn-success shadow-none w-100"
        [class.btn-loading]="inProgress"
        [disabled]="inProgress || !forgotPasswordForm.valid"
      >
        <span [hidden]="inProgress">Skicka</span>
      </button>
    </div>
  </form>
</div>
