import { Factory } from '../interface/factory';

export enum AttachmentType {
  None = 'None',
  Group = 'Group',
  Event = 'Event',
  Chat = 'Chat',
}

export class Attachment {
  type: AttachmentType;

  constructor(json: any, type: AttachmentType) {
    this.type = type;
  }

  static getFactory(): Factory<Attachment> {
    return new (class implements Factory<Attachment> {
      make(json: any): Attachment {
        switch (json.type) {
          case AttachmentType.None:
            return new NoneAttachment(json);
          case AttachmentType.Group:
            return new GroupAttachment(json);
          case AttachmentType.Event:
            return new EventAttachment(json);
          case AttachmentType.Chat:
            return new ChatAttachment(json);
          default:
            throw new Error('Unrecognized Attachment type (' + json.type + ').');
        }
      }

      getTableName(): string {
        throw new Error(
          'getTableName() should never be called on an Attachment. Attachments are not stored separately in IndexDB.'
        );
        return 'attachments';
      }
    })();
  }
}

export class NoneAttachment extends Attachment {
  constructor(json: any) {
    super(json, AttachmentType.None);
  }
}

export class GroupAttachment extends Attachment {
  id: string;
  constructor(json: any) {
    super(json, AttachmentType.Group);
    this.id = json.id;
  }
}

export class EventAttachment extends Attachment {
  id: string;
  constructor(json: any) {
    super(json, AttachmentType.Event);
    this.id = json.id;
  }
}

export class ChatAttachment extends Attachment {
  id: string;
  constructor(json: any) {
    super(json, AttachmentType.Chat);
    this.id = json.id;
  }
}
