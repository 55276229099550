import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Factory, Fault, TenantUnit, User } from '../models';
import { BehaviorSubject, catchError, from, Observable, of, tap, throwError } from 'rxjs';
import { CacheService } from './cache/cache.service';

interface DataResponse {
  data: any;
  extra: any;
}

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  private cache?: UserInStorage;
  private readonly apiUrl: string = environment.apiUrl;
  private readonly acceptHeader: string = environment.acceptHeader;

  constructor(private http: HttpClient) {}

  accessToken = localStorage.getItem('accessToken');
  refreshToken = localStorage.getItem('refreshToken');
  userId = localStorage.getItem('userId');

  async runRefreshToken() {
    const body = { data: this.refreshToken };

    const res = (await this.send('POST', '/users/' + this.userId + '/token/refresh', body, this.cache)) as DataResponse;

    if (!res.data) {
      throw new Error('Token refresh response has no data.');
    }

    // this.cache.accessToken = res.data;
    localStorage.setItem('accessToken', this.cache.accessToken); // Persist.
  }

  private async send(
    method: string,
    endpoint: string,
    body?: object,
    cache?: UserInStorage,
    cursor?: string,
    refresh: boolean = true
  ): Promise<object> {
    if (method === 'POST' || method === 'PUT') {
      if (!body) {
        throw new Error('Body can not be undefined for POST or PUT requests.');
      }
    } else if (body) {
      throw new Error('Only POST and PUT requests can have a body.');
    }

    const options = {
      headers: {
        Accept: this.acceptHeader,
      },
    };

    if (cache) {
      options['headers']['Authorization'] = 'Bearer ' + cache.accessToken;
    }

    if (method === 'PUT') {
      options['Content-Type'] = 'multipart/form-data;';
    }

    try {
      if (method === 'GET') {
        const res = await this.http.get(this.apiUrl + endpoint, options).toPromise();
        return res;
      } else if (method === 'POST') {
        const res = await this.http.post(this.apiUrl + endpoint, body, options).toPromise();
        return res;
      } else if (method === 'PUT') {
        const res = await this.http.put(this.apiUrl + endpoint, body, options).toPromise();
        return res;
      } else if (method === 'DELETE') {
        const res = await this.http.delete(this.apiUrl + endpoint, options).toPromise();
        return res;
      } else {
        throw new Error('Unrecognized method type.');
      }
    } catch (err) {
      if (err && err.status == 401 && refresh === true) {
        try {
          await this.runRefreshToken();
          return this.send(method, endpoint, body, cache, cursor, false);
        } catch (x) {
          throw err;
        }
      } else {
        const f = err.error?.fault;
        if (f) {
          // Is this a fault?
          throw new Fault(f);
        } else {
          throw err;
        }
      }
    }
  }

  public async getEntitiesFromApi<T>(url: string, factory: Factory<T>): Promise<T[]> {
    const res = (await this.send('GET', url, undefined, this.cache)) as any[];

    if (!res['data']) {
      throw new Error('Data not found.');
    }
    const returnObj = [];
    for (let data of res['data']) {
      console.log(data);
      returnObj.push(factory.make(data));
    }
    console.log(returnObj);
    return returnObj;
  }
}

export class UserInStorage {
  accessToken: string;
  refreshToken: string;
  userId: string;
  user: User;

  constructor(accessToken: string, refreshToken: string, userId: string) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.userId = userId;
  }

  public setUser(u: User) {
    this.user = u;
  }
}

class TenantUnitInStorage {
  userCache: UserInStorage;
  tenantUnitId: string;
  tenantUnit: TenantUnit;

  constructor(userCache: UserInStorage, tenantUnitId: string) {
    this.userCache = userCache;
    this.tenantUnitId = tenantUnitId;
  }

  setTenantUnit(t: TenantUnit) {
    this.tenantUnit = t;
  }
}

@Injectable({
  providedIn: 'root',
})
export class NewCacheService {
  // A HashMap to store the cache. The key is the page and the value is an object containing data and expiration time.
  private cache = new Map<string, { data: any[]; expiration: number }>();
  private userCache = new Map<string, { data: any; expiration: number }>();
  // BehaviorSubject that will contain the updated cache data.
  public cache$ = new BehaviorSubject<any[]>(null);
  public userCache$ = new BehaviorSubject<User>(null);

  // Set interval in milliseconds to check and clean expired cache entries
  private cleanupInterval = 60000; // 1 minute by default

  constructor() {
    // Set a periodic cleanup to remove expired cache entries
    setInterval(() => this.cleanupExpiredCache(), this.cleanupInterval);
  }
  /**
   * Set data in the cache with an optional expiration time in milliseconds.
   * @param key The cache key.
   * @param data The data to cache.
   * @param expirationTime Optional expiration time in milliseconds.
   */
  set(key: string, data: any[], expirationTime?: number): void {
    const currentTime = Date.now();
    const expiration = expirationTime ? currentTime + expirationTime : currentTime + this.cleanupInterval;

    // Check if data already exists for this key
    if (this.cache.has(key)) {
      this.clear(key);
      // throw new Error(`Data already exists for key '${key}'. Use a different key or delete the existing one first.`);
    }

    // Store data with expiration time
    this.cache.set(key, { data, expiration });
    this.cache$.next(this.cache.get(key)?.data);
  }

  /**
   * Set data in the cache with an optional expiration time in milliseconds.
   * @param key The cache key.
   * @param data The data to cache.
   * @param expirationTime Optional expiration time in milliseconds.
   */
  setUserOrTu(key: string, data): void {
    const currentTime = Date.now();
    const expiration = currentTime + this.cleanupInterval * 60;

    // Check if data already exists for this key
    if (this.userCache.has(key)) {
      this.userCache.delete(key);
    }

    // Store data with expiration time
    this.userCache.set(key, { data, expiration });
    this.userCache$.next(this.userCache.get(key)?.data);
  }

  /**
   * Get data from the cache. Returns undefined if the data is expired or doesn't exist.
   * @param key The cache key.
   * @returns The cached data or undefined if expired or not found.
   */
  get(key: string): any[] | undefined {
    const cacheEntry = this.cache.get(key);
    if (cacheEntry && cacheEntry.expiration > Date.now()) {
      this.cache$.next(cacheEntry.data);
      return cacheEntry.data;
    } else {
      // If the data is expired or doesn't exist, clear the entry
      this.clear(key);
      return undefined;
    }
  }

  /**
   * Get data from the cache. Returns undefined if the data is expired or doesn't exist.
   * @param key The cache key.
   * @returns The cached data or undefined if expired or not found.
   */
  getUserOrTu(key: string): User | undefined {
    const cacheEntry = this.userCache.get(key);
    if (cacheEntry && cacheEntry.expiration > Date.now()) {
      this.userCache$.next(cacheEntry.data);
      return cacheEntry.data;
    } else {
      // If the data is expired or doesn't exist, clear the entry
      this.clear(key);
      return undefined;
    }
  }

  /**
   * Clear a specific key from the cache.
   * @param key The cache key to clear.
   */
  clear(key: string): void {
    this.cache.delete(key);
    this.cache$.next(null);
  }

  /**
   * Clears all expired cache entries.
   */
  private cleanupExpiredCache(): void {
    const currentTime = Date.now();
    this.cache.forEach((value, key) => {
      if (value.expiration <= currentTime) {
        this.cache.delete(key);
      }
    });
    this.userCache.forEach((value, key) => {
      if (value.expiration <= currentTime) {
        this.userCache.delete(key);
      }
    });
  }

  /**
   * Optional: Clears the entire cache.
   */
  clearAll(): void {
    this.cache.clear();
    this.userCache.clear();
    this.cache$.next(null);
    this.userCache$.next(null);
  }

  /**
   * Set the interval for cleaning expired cache items.
   * @param interval The interval in milliseconds.
   */
  setCleanupInterval(interval: number): void {
    this.cleanupInterval = interval;
    // Clear existing interval and set a new one
    clearInterval(this.cleanupInterval);
    setInterval(() => this.cleanupExpiredCache(), this.cleanupInterval);
  }
}
