import { Identifiable } from '../interface/identifiable';
import { Deletable } from '../interface/deletable';
import { Factory } from '../interface/factory';
import { ParticipantStatus } from '../enum/participant-status';

export class Chat implements Deletable, Identifiable {
  id: string;
  deleted: boolean;
  deletedDbFlag: 0 | 1; // IndexDB can't index by boolean so we use a number to enable indexing by deleted.

  tenantUnitId: string;
  participants: Participant[];

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = json.deleted ? 1 : 0;

    this.tenantUnitId = json.tenantUnitId;
    this.participants = json.participants ? json.participants.map((json: any) => new Participant(json)) : [];

    this.created = json.created;
    this.modified = json.modified;
  }

  public static getFactory(): Factory<Chat> {
    return new (class implements Factory<Chat> {
      make(json: any): Chat {
        return new Chat(json);
      }
      getTableName(): string {
        return 'chats';
      }
    })();
  }

  static getUrl(tenantUnitId: string, chatId?: string): string;
  static getUrl(tenantUnitId: string, chatId: string): string {
    return '/tenant_units/' + tenantUnitId + '/chats' + (chatId ? '/' + chatId : '');
  }
}

export class Participant {
  userId: string;
  status: string;
  writing?: Date;

  constructor(json: any) {
    this.userId = json.userId;
    this.status = json.status as ParticipantStatus;
    this.writing = json.writing ? json.writing : undefined;
  }
}
