import { Role, ApartmentRole, TenantUnitRole } from './role';
import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { Gender } from '../enum/gender';
import { Device } from './device';
import { Flags } from './flags';
import { Scope } from '../enum/scope';

type SrenityPersonId = {
  tenantUnitId: string;
  personId: string;
};

export class User implements Deletable, Identifiable {
  id: string;
  deleted?: boolean;

  // Srenity person ID
  srenityPersons: SrenityPersonId[];

  private: boolean;
  test?: boolean;

  flags?: Flags;

  phoneVerificationCode?: string;

  firstName: string;
  preferredName?: string;
  middleNames?: string;
  lastName: string;

  email?: string;
  nid?: string;
  phone?: string;
  dob?: string;
  image?: string;
  gender: Gender;

  roles: Role[];
  devices: Device[];
  blacklist: string[];

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;

    this.srenityPersons = json.srenityPersons;

    this.private = json.private ? Boolean(json.private) : false;
    this.test = json.test ? Boolean(json.test) : false;

    this.flags = json.flags ? new Flags(json.flags) : undefined;

    this.phoneVerificationCode = json.phoneVerificationCode ? json.phoneVerificationCode : undefined;

    this.firstName = json.firstName;
    this.preferredName = json.preferredName ? json.preferredName : undefined;
    this.middleNames = json.middleNames ? json.middleNames : undefined;
    this.lastName = json.lastName;

    this.email = json.email ? json.email : undefined;
    this.nid = json.nid ? json.nid : undefined;
    this.phone = json.phone ? json.phone : undefined;
    this.dob = json.dob;
    this.image = json.image ? json.image : undefined;
    this.gender = json.gender as Gender;

    const roleFactory: Factory<Role> = Role.getFactory();
    this.roles = json.roles ? json.roles.map((json: any) => roleFactory.make(json)) : [];
    this.devices = json.devices ? json.devices.map((json: any) => new Device(json)) : [];
    this.blacklist = json.blacklist;

    this.created = json.created;
    this.modified = json.modified;
  }

  public static getFactory(): Factory<User> {
    return new (class implements Factory<User> {
      make(json: any): User {
        return new User(json);
      }

      getTableName(): string {
        return 'users';
      }
    })();
  }

  static getUrl(): string;
  static getUrl(userId: string): string;
  static getUrl(userId?: string): string {
    return '/users' + (userId ? '/' + userId : '');
  }

  getName(showMiddleName: boolean = false): string {
    return showMiddleName && this.middleNames
      ? this.firstName.trim() + ' ' + this.middleNames.trim() + ' ' + this.lastName.trim()
      : this.firstName.trim() + ' ' + this.lastName.trim();
  }

  getRolesByTenantUnit(tenantUnitId: string): Role[] {
    return this.roles.filter(
      (r) =>
        (r instanceof TenantUnitRole || r instanceof ApartmentRole) && r.tenantUnitId === tenantUnitId && r.scopes > 0
    );
  }

  getApartmentRolesByTenantUnit(tenantUnitId: string): ApartmentRole[] {
    const roles = [];
    for (let i = 0; i < this.roles.length; i++) {
      const r = this.roles[i];
      if (r instanceof ApartmentRole && r.tenantUnitId === tenantUnitId && (r.scopes & Scope.ApartmentResident) > 0) {
        roles.push(r);
      }
    }
    return roles;
  }

  isBoardMember(tenantUnitId: string): boolean {
    for (let i = 0; i < this.roles.length; i++) {
      const r = this.roles[i];
      if (
        r instanceof TenantUnitRole &&
        r.tenantUnitId === tenantUnitId &&
        (r.scopes & Scope.TenantUnitBoardMember) > 0
      ) {
        return true;
      }
    }
    return false;
  }

  isContractOwner(tenantUnitId: string): boolean {
    for (let i = 0; i < this.roles.length; i++) {
      const r = this.roles[i];
      if (
        r instanceof ApartmentRole &&
        r.tenantUnitId === tenantUnitId &&
        (r.scopes & Scope.ApartmentContractOwner) > 0
      ) {
        return true;
      }
    }
    return false;
  }

  isTenantUnitPersonnelAdmin(tenantUnitId: string): boolean {
    for (let i = 0; i < this.roles.length; i++) {
      const r = this.roles[i];
      if (
        r instanceof TenantUnitRole &&
        r.tenantUnitId === tenantUnitId &&
        (r.scopes & Scope.TenantUnitPersonnelAdmin) > 0
      ) {
        return true;
      }
    }
    return false;
  }

  isTenantUnitContentAdmin(tenantUnitId: string): boolean {
    for (let i = 0; i < this.roles.length; i++) {
      const r = this.roles[i];
      if (
        r instanceof TenantUnitRole &&
        r.tenantUnitId === tenantUnitId &&
        (r.scopes & Scope.TenantUnitContentAdmin) > 0
      ) {
        return true;
      }
    }
    return false;
  }

  isTenantUnitAdmin(tenantUnitId: string): boolean {
    for (let i = 0; i < this.roles.length; i++) {
      const r = this.roles[i];

      // Only look at TenantUnit roles.
      if (!(r instanceof TenantUnitRole)) {
        continue;
      }

      // Only look at TenantUnit roles for the given TenantUnit.
      if (r.tenantUnitId !== tenantUnitId) {
        continue;
      }

      // TenantUnitContentAdmin = 16,
      // TenantUnitBillingAdmin = 32,
      // TenantUnitPersonnelAdmin = 64,
      const tuContentAdmin = (r.scopes & Scope.TenantUnitContentAdmin) > 0;
      const tuBillingAdmin = (r.scopes & Scope.TenantUnitBillingAdmin) > 0;
      const tuPersonnelAdmin = (r.scopes & Scope.TenantUnitPersonnelAdmin) > 0;
      // GlobalContentAdmin = 128,
      // GlobalBillingAdmin = 256,
      // GlobalPersonnelAdmin = 512,
      const gContentAdmin = (r.scopes & Scope.GlobalContentAdmin) > 0;
      const gBillingAdmin = (r.scopes & Scope.GlobalBillingAdmin) > 0;
      const gPersonnelAdmin = (r.scopes & Scope.GlobalPersonnelAdmin) > 0;

      if (tuContentAdmin || tuBillingAdmin || tuPersonnelAdmin || gContentAdmin || gBillingAdmin || gPersonnelAdmin) {
        return true;
      }
    }

    return false;
  }

  isResidentInUnit(tenantUnitId: string): boolean {
    for (let i = 0; i < this.roles.length; i++) {
      const r = this.roles[i];
      if (r instanceof ApartmentRole && r.tenantUnitId === tenantUnitId && (r.scopes & Scope.ApartmentResident) > 0) {
        return true;
      }
    }
    return false;
  }

  isShownOnDoor(tenantUnitId: string): boolean {
    for (let i = 0; i < this.roles.length; i++) {
      const r = this.roles[i];
      if (r instanceof ApartmentRole && r.tenantUnitId === tenantUnitId && (r.scopes & Scope.ApartmentShowOnDoor) > 0) {
        return true;
      }
    }
    return false;
  }
}
