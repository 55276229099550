import { Factory } from '../interface/factory';
import { Scope } from '../enum/scope';

export enum RoleType {
  Global = 'Global',
  TenantUnit = 'TenantUnit',
  Apartment = 'Apartment',
}

export abstract class Role {
  type: RoleType;
  scopes: Scope;

  constructor(json: any, type: RoleType) {
    this.type = type;
    this.scopes = json.scopes as Scope;
  }

  static getFactory(): Factory<Role> {
    return new (class implements Factory<Role> {
      make(json: any): Role {
        switch (json.type) {
          case RoleType.Global:
            return new GlobalRole(json);
          case RoleType.TenantUnit:
            return new TenantUnitRole(json);
          case RoleType.Apartment:
            return new ApartmentRole(json);
          default:
            throw new Error('Unrecognized Role type (' + json.type + ').');
        }
      }

      getTableName(): string {
        throw new Error('getTableName() should never be called on a Roles. Roles are not stored in IndexDB.');
        return 'roles';
      }
    })();
  }
}

export class GlobalRole extends Role {
  constructor(json: any) {
    super(json, RoleType.Global);
  }
}

export class TenantUnitRole extends Role {
  tenantUnitId: string;
  constructor(json: any) {
    super(json, RoleType.TenantUnit);
    this.tenantUnitId = json.tenantUnitId;
  }
}

export class ApartmentRole extends Role {
  tenantUnitId: string;
  apartmentId: string;
  constructor(json: any) {
    super(json, RoleType.Apartment);
    this.tenantUnitId = json.tenantUnitId;
    this.apartmentId = json.apartmentId;
  }
}
