import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { HasTenantUnitId } from '../interface/has-tenant-unit-id';
import { HasApartmentId } from '../interface/has-apartment-id';

export enum InviteType {
  ApartmentResidentNid = 'ApartmentResidentNid',
  ApartmentResidentEmail = 'ApartmentResidentEmail',
}

export abstract class Invite implements Deletable, Identifiable, HasTenantUnitId {
  type: InviteType;

  id: string;
  deleted: boolean;
  deletedDbFlag: 0 | 1; // NOTE: Only used for indexing in browser DB.

  tenantUnitId: string;
  userId?: string;

  created: Date;
  modified: Date;

  constructor(json: any, type: InviteType) {
    this.type = type;

    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = json.deleted ? 1 : 0;

    this.tenantUnitId = json.tenantUnitId;
    this.userId = json.userId;

    this.created = json.created;
    this.modified = json.modified;
  }

  static getFactory(): Factory<Invite> {
    return new (class implements Factory<Invite> {
      make(json: any): Invite {
        switch (json.type) {
          case InviteType.ApartmentResidentNid:
            return new ApartmentNidInvite(json);
          case InviteType.ApartmentResidentEmail:
            return new ApartmentEmailInvite(json);
          default:
            throw new Error('Unrecognized Invite type (' + json.type + ').');
        }
      }

      getTableName(): string {
        return 'invites';
      }
    })();
  }
}

export class ApartmentNidInvite extends Invite implements HasApartmentId {
  pk: string; // Partition key, tenant unit id + _ + apartment id.
  apartmentId: string;
  nid?: string;

  constructor(json: any) {
    super(json, InviteType.ApartmentResidentNid);
    this.pk = json.pk;
    this.apartmentId = json.apartmentId;
    this.nid = json.nid ? json.nid : undefined;
  }
}

export class ApartmentEmailInvite extends Invite implements HasApartmentId {
  id: string;
  pk: string; // Partition key, tenant unit id + _ + apartment id.
  apartmentId: string;

  email?: string;
  emailSent?: Date;

  firstName?: string;
  lastName?: string;

  makeContractOwner: boolean;
  showOnDoor: boolean;
  showOnDoorPhone: boolean;

  constructor(json: any) {
    super(json, InviteType.ApartmentResidentEmail);
    this.pk = json.pk;
    this.id = json.id;
    this.apartmentId = json.apartmentId;

    this.email = json.email ? json.email : undefined;
    this.emailSent = json.emailSent;

    this.firstName = json.firstName ? json.firstName : undefined;
    this.lastName = json.lastName ? json.lastName : undefined;

    this.makeContractOwner = json.makeContractOwner ? Boolean(json.makeContractOwner) : false;
    this.showOnDoor = json.showOnDoor ? Boolean(json.showOnDoor) : false;
    this.showOnDoorPhone = json.showOnDoorPhone ? Boolean(json.showOnDoorPhone) : false;
  }

  static getUrl(tenantUnitId: string, apartmentId: string, inviteId?: string): string;
  static getUrl(tenantUnitId: string, apartmentId: string, inviteId: string): string {
    return (
      '/tenant_units/' + tenantUnitId + '/apartments/' + apartmentId + '/invites' + (inviteId ? '/' + inviteId : '')
    );
  }

  getName(): string | undefined {
    if (this.firstName !== undefined && this.lastName !== undefined) {
      return this.firstName + ' ' + this.lastName;
    } else if (this.firstName !== undefined) {
      return this.firstName;
    } else if (this.lastName !== undefined) {
      return this.lastName;
    }
    return undefined;
  }
}
