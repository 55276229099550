import { Identifiable } from '../interface/identifiable';
import { Deletable } from '../interface/deletable';
import { HasTenantUnitId } from '../interface/has-tenant-unit-id';
import { Factory } from '../interface/factory';
import { I18nString } from './i18nstring';

export class Building implements Deletable, Identifiable, HasTenantUnitId {
  id: string;
  deleted?: boolean;

  tenantUnitId: string;
  srenityLockIds?: string[];

  names: I18nString[];
  intros: I18nString[];
  abouts: I18nString[];

  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;

    this.tenantUnitId = json.tenantUnitId;
    this.srenityLockIds = json.srenityLockIds ? json.srenityLockIds : [];

    this.names = json.names ? json.names.map((json: any) => new I18nString(json)) : [];
    this.intros = json.intros ? json.intros.map((json: any) => new I18nString(json)) : [];
    this.abouts = json.abouts ? json.abouts.map((json: any) => new I18nString(json)) : [];

    this.modified = json.modified;
  }

  public static getFactory(): Factory<Building> {
    return new (class implements Factory<Building> {
      make(json: any): Building {
        return new Building(json);
      }
      getTableName(): string {
        return 'buildings';
      }
    })();
  }

  static getUrl(tenantUnitId: string, buildingId?: string): string;
  static getUrl(tenantUnitId: string, buildingId: string): string {
    return '/tenant_units/' + tenantUnitId + '/buildings' + (buildingId ? '/' + buildingId : '');
  }
}
