import { Identifiable } from '../interface/identifiable';
import { Deletable } from '../interface/deletable';
import { Factory } from '../interface/factory';
import { HasTenantUnitId } from '../interface/has-tenant-unit-id';
import { HasBuildingId } from '../interface/has-building-id';

export class Level implements Deletable, Identifiable, HasTenantUnitId, HasBuildingId {
  id: string;
  deleted?: boolean;
  deletedDbFlag: 0 | 1; // NOTE: Only used for indexing in browser DB.

  tenantUnitId: string;
  buildingId: string;
  position: number;

  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = this.deleted ? 1 : 0;

    this.tenantUnitId = json.tenantUnitId;
    this.buildingId = json.buildingId;
    this.position = Number(json.position);

    this.modified = json.modified;
  }

  public static getFactory(): Factory<Level> {
    return new (class implements Factory<Level> {
      make(json: any): Level {
        return new Level(json);
      }
      getTableName(): string {
        return 'levels';
      }
    })();
  }

  static getUrl(tenantUnitId: string, buildingId: string, levelId?: string): string;
  static getUrl(tenantUnitId: string, buildingId: string, levelId?: string): string {
    return '/tenant_units/' + tenantUnitId + '/buildings/' + buildingId + '/levels' + (levelId ? '/' + levelId : '');
  }
}
