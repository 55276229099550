import { environment } from '../../../environments/environment';
import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { PublishStatus } from '../enum/publish-status';
import { I18nString } from '../class/i18nstring';
import { Role } from './role';

export class Group implements Deletable, Identifiable {
  id: string;
  deleted: boolean;

  tenantUnitId: string;

  status: PublishStatus;

  names: I18nString[];
  longNames: I18nString[];
  shortDescriptions: I18nString[];
  longDescriptions: I18nString[];

  images: string[];

  members: string[]; // User IDs
  requiredRoles: Role[];

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;

    this.tenantUnitId = json.tenantUnitId;

    this.status = json.status ? (json.status as PublishStatus) : PublishStatus.Published;

    this.names = json.names ? json.names.map((json: any) => new I18nString(json)) : [];
    this.longNames = json.longNames ? json.longNames.map((json: any) => new I18nString(json)) : [];
    this.shortDescriptions = json.shortDescriptions
      ? json.shortDescriptions.map((json: any) => new I18nString(json))
      : [];
    this.longDescriptions = json.longDescriptions ? json.longDescriptions.map((json: any) => new I18nString(json)) : [];

    this.images = json.images ? json.images : [];

    this.members = json.members;
    const roleFactory: Factory<Role> = Role.getFactory();
    this.requiredRoles = json.requiredRoles ? json.requiredRoles.map((json: any) => roleFactory.make(json)) : [];

    this.created = json.created;
    this.modified = json.modified;
  }

  public static getFactory(): Factory<Group> {
    return new (class implements Factory<Group> {
      make(json: any): Group {
        return new Group(json);
      }
      getTableName(): string {
        return 'groups';
      }
    })();
  }

  static getAllUrl(tenantUnitId: string): string;
  static getAllUrl(tenantUnitId: string): string {
    return '/tenant_units/' + tenantUnitId + '/groups/all';
  }

  static getUrl(tenantUnitId: string, groupId?: string): string;
  static getUrl(tenantUnitId: string, groupId: string): string {
    return '/tenant_units/' + tenantUnitId + '/groups' + (groupId ? '/' + groupId : '');
  }

  getImageUrl(index: number = 0): string | undefined {
    return this.images[index] ? environment.blobUrl + '/images/' + this.images[index] : undefined;
  }
}
