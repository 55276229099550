import { Identifiable } from '../interface/identifiable';
import { Deletable } from '../interface/deletable';
import { HasTenantUnitId } from '../interface/has-tenant-unit-id';
import { Factory } from '../interface/factory';

export class Entrance implements Deletable, Identifiable, HasTenantUnitId {
  id: string;
  deleted?: boolean;
  deletedDbFlag: 0 | 1; // NOTE: Only used for indexing in browser DB.

  tenantUnitId: string;
  buildingId: string;

  address: string;
  postcode: string;
  city: string;
  province?: string;
  country: string;

  timezone: string;
  latitude: number;
  longitude: number;

  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = json.deleted ? 1 : 0;

    this.tenantUnitId = json.tenantUnitId;
    this.buildingId = json.buildingId;

    this.address = json.address;
    this.postcode = json.postcode;
    this.city = json.city;
    this.province = json.province ? json.province : undefined;
    this.country = json.country;

    this.timezone = json.timezone;
    this.latitude = Number(json.latitude);
    this.longitude = Number(json.longitude);

    this.modified = json.modified;
  }

  public static getFactory(): Factory<Entrance> {
    return new (class implements Factory<Entrance> {
      make(json: any): Entrance {
        return new Entrance(json);
      }
      getTableName(): string {
        return 'entrances';
      }
    })();
  }

  static getUrl(tenantUnitId: string, entranceId?: string): string;
  static getUrl(tenantUnitId: string, entranceId: string): string {
    return '/tenant_units/' + tenantUnitId + '/entrances' + (entranceId ? '/' + entranceId : '');
  }
}
