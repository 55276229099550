import { Duration } from './duration';
import { Schedule } from './schedule';

export class BookableSettings {
  meetingRoom: MeetingRoomSettings;
  cargoBike: CargoBikeSettings;
  generic: GenericBookableSettings;

  constructor(json: any) {
    this.meetingRoom = new MeetingRoomSettings(json.meetingRoom);
    this.cargoBike = new CargoBikeSettings(json.cargoBike);
    this.generic = new GenericBookableSettings(json.generic);
  }
}

export class MeetingRoomSettings {
  maxSimultaneousBookings?: number;
  slotLength: Duration;
  maxLength?: Duration;
  availability?: Schedule<boolean>;

  constructor(json: any) {
    this.maxSimultaneousBookings = json.maxSimultaneousBookings ? Number(json.maxSimultaneousBookings) : undefined;
    this.slotLength = new Duration(json.slotLength);
    this.maxLength = json.maxLength !== undefined ? new Duration(json.maxLength) : undefined;
    this.availability = json.availability ? new Schedule<boolean>(json.availability) : undefined;
  }
}

export class CargoBikeSettings {
  maxSimultaneousBookings?: number;
  slotLength: Duration;
  maxLength?: Duration;
  availability: Schedule<boolean>;

  constructor(json: any) {
    this.maxSimultaneousBookings = json.maxSimultaneousBookings ? Number(json.maxSimultaneousBookings) : undefined;
    this.slotLength = new Duration(json.slotLength);
    this.maxLength = json.maxLength ? new Duration(json.maxLength) : undefined;
    this.availability = new Schedule<boolean>(json.availability);
  }
}

export class GenericBookableSettings {
  maxSimultaneousBookings?: number;
  slotLength: Duration;
  maxLength?: Duration;
  availability: Schedule<boolean>;

  constructor(json: any) {
    this.maxSimultaneousBookings = json.maxSimultaneousBookings ? Number(json.maxSimultaneousBookings) : undefined;
    this.slotLength = new Duration(json.slotLength);
    this.maxLength = json.maxLength ? new Duration(json.maxLength) : undefined;
    this.availability = new Schedule<boolean>(json.availability);
  }
}
