import { environment } from '../../../environments/environment';
import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { I18nString } from '../class/i18nstring';
import { Duration } from './duration';
import { Schedule } from './schedule';
import { Asset } from './asset';

export enum BookableType {
  CargoBike = 'CargoBike',
  Generic = 'Generic',
  MeetingRoom = 'MeetingRoom',
}

export class Bookable implements Deletable, Identifiable {
  id: string;
  deleted: boolean;

  hourlyPrice: number;
  hourlyPriceVat: number;

  type: BookableType;

  tenantUnitId: string;
  names: I18nString[];
  abouts: I18nString[];

  // Deprecated. Should be removed when Heimstaden has moved to the Serneke-equivalent codebase.
  maxSimultaneousBookings?: number;
  // Deprecated. Should be removed when Heimstaden has moved to the Serneke-equivalent codebase.
  allowedBookingTimes: Schedule<Boolean>;
  // Deprecated. Should be removed when Heimstaden has moved to the Serneke-equivalent codebase.
  maxRentTime: Duration;

  maxLength?: Duration;

  created: Date;
  modified: Date;

  constructor(json: any, type: BookableType) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;

    this.hourlyPrice = json.hourlyPrice;
    this.hourlyPriceVat = json.hourlyPriceVat;

    this.type = type;

    this.tenantUnitId = json.tenantUnitId;
    this.names = json.names ? json.names.map((json: any) => new I18nString(json)) : [];
    this.abouts = json.abouts ? json.abouts.map((json: any) => new I18nString(json)) : [];

    this.maxSimultaneousBookings = json.maxSimultaneousBookings ? Number(json.maxSimultaneousBookings) : undefined;
    this.allowedBookingTimes = new Schedule<Boolean>(json.allowedBookingTimes);
    this.maxRentTime = new Duration(json.maxRentTime);

    this.maxLength = json.maxLength !== undefined ? new Duration(json.maxLength) : undefined;

    this.created = json.created;
    this.modified = json.modified;
  }

  static getFactory(): Factory<Bookable> {
    return new (class implements Factory<Bookable> {
      make(json: any): Bookable {
        switch (json.type) {
          case BookableType.Generic:
            return new GenericBookable(json);
          case BookableType.CargoBike:
            return new CargoBike(json);
          case BookableType.MeetingRoom:
            return new MeetingRoom(json);
          default:
            throw new Error('Unrecognized Bookable type (' + json.type + ').');
        }
      }

      getTableName(): string {
        return 'bookables';
      }
    })();
  }

  static getUrl(tenantUnitId: string, bookableId?: string): string;
  static getUrl(tenantUnitId: string, bookableId: string): string {
    return '/tenant_units/' + tenantUnitId + '/bookables' + (bookableId ? '/' + bookableId : '');
  }
}

export class GenericBookable extends Bookable {
  categoryId?: string;
  images: string[];

  constructor(json: any) {
    super(json, BookableType.Generic);
    this.categoryId = json.categoryId ? json.categoryId : undefined;
    this.images = json.images ? json.images : [];
  }

  getImageUrl(index: number = 0): string | undefined {
    return this.images[index] ? environment.blobUrl + '/images/' + this.images[index] : undefined;
  }
}

export class CargoBike extends Bookable {
  code: string;
  images: string[];

  constructor(json: any) {
    super(json, BookableType.CargoBike);
    this.code = json.code;
    this.images = json.images ? json.images : [];
  }

  getImageUrl(index: number = 0): string | undefined {
    return this.images[index] ? environment.blobUrl + '/images/' + this.images[index] : undefined;
  }
}

export class MeetingRoom extends Bookable {
  capacity: number;
  size: number; // Square meters.
  images: string[];
  availability?: Schedule<boolean>;
  assets: Asset[];

  constructor(json: any) {
    super(json, BookableType.MeetingRoom);
    this.capacity = Number(json.capacity);

    // NOTE: Size field may come undefined from API since there are objects
    // in the database that were created before this field was added.
    this.size = json?.size !== undefined || json?.size !== null ? Number(json.size) : 10;

    this.images = json.images ? json.images : [];
    this.availability = json.availability ? new Schedule<boolean>(json.availability) : undefined;
    const assetFactory = Asset.getFactory();
    this.assets = json.assets ? json.assets.map((aj: any) => assetFactory.make(aj)) : [];
  }

  getImageUrl(index: number = 0): string | undefined {
    return this.images[index] ? environment.blobUrl + '/images/' + this.images[index] : undefined;
  }
}
