import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { I18nString } from './i18nstring';
import { Role } from './role';

// ResidentialInformationLink
export class R20nLink implements Identifiable {
  id: string; // NOTE: Used for deleting.
  titles: I18nString[];
  url: string; // Full url to file.
  userEditable: boolean;
  requiredRoles: Role[];

  constructor(json: any) {
    this.id = json.id;
    this.titles = json.titles ? json.titles.map((json: any) => new I18nString(json)) : [];
    this.url = json.url;
    this.userEditable = json.userEditable ? Boolean(json.userEditable) : false;
    const roleFactory: Factory<Role> = Role.getFactory();
    this.requiredRoles = json.requiredRoles ? json.requiredRoles.map((json: any) => roleFactory.make(json)) : [];
  }
}
