import { WeekDays } from '../enum/week-days';

export class Rule<T> {
  private start: Date; // Zulu-labeled naive date.
  private end: Date; // Zulu-labeled naive date.

  weekdays?: WeekDays;
  state: T;
  transient: boolean;

  constructor(json: any) {
    this.start = new Date(json.start);
    this.end = new Date(json.end);
    this.weekdays = json.weekdays ? (json.weekdays as WeekDays) : undefined;
    this.state = json.state;
    this.transient = Boolean(json.transient);
  }

  // Convert zulu-labeled naive date to local Date with exact same values.
  getLocalStart(): Date {
    return new Date(
      this.start.getUTCFullYear(),
      this.start.getUTCMonth(),
      this.start.getUTCDate(),
      this.start.getUTCHours(),
      this.start.getUTCMinutes(),
      this.start.getUTCSeconds()
    );
  }

  // Convert zulu-labeled naive date to local Date with exact same values.
  getLocalEnd(): Date {
    return new Date(
      this.end.getUTCFullYear(),
      this.end.getUTCMonth(),
      this.end.getUTCDate(),
      this.end.getUTCHours(),
      this.end.getUTCMinutes(),
      this.end.getUTCSeconds()
    );
  }
}
