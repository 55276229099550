import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { BookableType } from './bookable';

export class Booking implements Deletable, Identifiable {
  id: string;
  deleted: boolean;
  deletedDbFlag: 0 | 1; // IndexDB can't index by boolean so we use a number to enable indexing by deleted.

  type: BookableType;

  tenantUnitId: string;
  bookableId: string;
  userId: string;

  start: string;
  end: string;

  created: string;
  modified: string;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = json.deleted ? 1 : 0;

    this.type = json.type as BookableType;

    this.tenantUnitId = json.tenantUnitId;
    this.bookableId = json.bookableId;
    this.userId = json.userId;

    this.start = json.start;
    this.end = json.end;

    this.created = json.created;
    this.modified = json.modified;
  }

  public static getFactory(): Factory<Booking> {
    return new (class implements Factory<Booking> {
      make(json: any): Booking {
        return new Booking(json);
      }
      getTableName(): string {
        return 'bookings';
      }
    })();
  }

  static getUrl(tenantUnitId: string, bookableId: string, bookingId?: string): string;
  static getUrl(tenantUnitId: string, bookableId: string, bookingId: string): string {
    return (
      '/tenant_units/' + tenantUnitId + '/bookables/' + bookableId + '/bookings' + (bookingId ? '/' + bookingId : '')
    );
  }
}
