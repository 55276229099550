import { Component, Input, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CacheService } from 'src/app/services/cache/cache.service';
import { Bookable } from 'src/app/models';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: [],
})
export class ForgotPasswordModal implements OnInit {
  @Input() bookable: Bookable;

  readonly activeUnitId: string;

  inProgress: boolean;

  forgotPasswordForm: UntypedFormGroup;

  submitFailed: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private cache: CacheService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.activeUnitId = this.cache.getActiveTenantUnitId();

    this.inProgress = false;

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  async ngOnInit() {}

  validationColor(value: boolean | null) {
    if (value === null) {
      return 'inherit';
    }

    return value === true ? '#00c853' : '#ee5555';
  }

  async submitForgotPassword() {
    this.inProgress = true;
    const email = this.forgotPasswordForm.get('email').value;

    try {
      await this.cache.forgotPassword(email);
    } catch (err) {
      console.log('error changing password: ', err);

      this.inProgress = false;

      return;
    }

    this.inProgress = false;
    this.closeModal();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeModal();
  }

  closeModal(result?: any) {
    this.activeModal.close(result);
  }
}
