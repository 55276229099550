import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { HasTenantUnitId } from '../interface/has-tenant-unit-id';
import { Factory } from '../interface/factory';
import { PublishStatus } from '../enum/publish-status';

export class PostMessage implements Deletable, Identifiable, HasTenantUnitId {
  id: string;
  deleted: boolean;
  deletedDbFlag: 0 | 1; // NOTE: Only used for indexing in browser DB.

  tenantUnitId: string;
  postId: string;
  userId: string;
  status: PublishStatus;
  text: string;

  showAsSystemUser: boolean;

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = json.deleted ? 1 : 0;

    this.tenantUnitId = json.tenantUnitId;
    this.postId = json.postId;
    this.userId = json.userId;
    this.status = json.status as PublishStatus;
    this.text = json.text;

    this.showAsSystemUser = json.showAsSystemUser ? Boolean(json.showAsSystemUser) : false;

    this.created = json.created;
    this.modified = json.modified;
  }

  public static getFactory(): Factory<PostMessage> {
    return new (class implements Factory<PostMessage> {
      make(json: any): PostMessage {
        return new PostMessage(json);
      }
      getTableName(): string {
        return 'post_messages';
      }
    })();
  }

  static getUrl(tenantUnitId: string, postId: string, messageId?: string): string;
  static getUrl(tenantUnitId: string, postId: string, messageId: string): string {
    return '/tenant_units/' + tenantUnitId + '/posts/' + postId + '/messages' + (messageId ? '/' + messageId : '');
  }
}
