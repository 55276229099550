import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { HasTenantUnitId } from '../interface/has-tenant-unit-id';
import { Factory } from '../interface/factory';
import { I18nString } from '../class/i18nstring';

export class Apartment implements Deletable, Identifiable, HasTenantUnitId {
  id: string;
  deleted: boolean;
  deletedDbFlag: 0 | 1; // NOTE: Only used for indexing in browser DB.

  tenantUnitId: string;
  buildingId: string;
  entranceIds: string[];
  roomIds: string[];
  objectNo: string; // Serneke_769637-1652_1-1604
  srenityApartmentId?: string;
  names: I18nString[];
  showOnSignage?: boolean;
  //showImdData?: boolean;

  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = this.deleted ? 1 : 0;

    this.tenantUnitId = json.tenantUnitId;
    this.buildingId = json.buildingId;
    this.entranceIds = json.entranceIds;
    this.roomIds = json.roomIds;
    this.objectNo = json.objectNo;
    this.showOnSignage = json.showOnSignage;
    this.srenityApartmentId = json.srenityApartmentId;
    this.names = json.names ? json.names.map((json: any) => new I18nString(json)) : [];

    this.modified = json.modified;
  }

  public static getFactory(): Factory<Apartment> {
    return new (class implements Factory<Apartment> {
      make(json: any): Apartment {
        return new Apartment(json);
      }
      getTableName(): string {
        return 'apartments';
      }
    })();
  }

  static getAllUrl(tenantUnitId: string): string;
  static getAllUrl(tenantUnitId: string): string {
    return '/tenant_units/' + tenantUnitId + '/apartments/all';
  }
  static getApartmentUsers(tenantUnitId: string, apartmentId: string): string;
  static getApartmentUsers(tenantUnitId: string, apartmentId: string): string {
    return '/tenant_units/' + tenantUnitId + '/apartments/' + apartmentId + '/users';
  }
  static getApartmentInvites(tenantUnitId: string, apartmentId: string): string;
  static getApartmentInvites(tenantUnitId: string, apartmentId: string): string {
    return '/tenant_units/' + tenantUnitId + '/apartments/' + apartmentId + '/invites/all';
  }
  static getApartmenmtUrl(tenantUnitId: string, apartmentId: string): string;
  static getApartmenmtUrl(tenantUnitId: string, apartmentId: string): string {
    return '/tenant_units/' + tenantUnitId + '/apartments/' + apartmentId;
  }
  static getUrl(tenantUnitId: string, apartmentId?: string): string;
  static getUrl(tenantUnitId: string, apartmentId: string): string {
    return '/tenant_units/' + tenantUnitId + '/apartments' + (apartmentId ? '/' + apartmentId : '');
  }
}
