import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { HasTenantUnitId } from '../interface/has-tenant-unit-id';
import { Factory } from '../interface/factory';
import { I18nString } from './i18nstring';
import { R20nLink } from './r20n-link';
import { R20nBox } from './r20n-box';

// ResidentialInformationCategory
export class R20nCategory implements Deletable, Identifiable, HasTenantUnitId {
  id: string;
  deleted: boolean;
  deletedDbFlag: 0 | 1; // NOTE: Only used for indexing in browser DB.

  tenantUnitId: string;
  parentId?: string;

  titles: I18nString[];

  links: R20nLink[];
  boxes: R20nBox[];

  userLinksUploadable: boolean;

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = json.deleted ? 1 : 0;

    this.tenantUnitId = json.tenantUnitId;
    this.parentId = json.parentId ? json.parentId : undefined;

    this.titles = json.titles ? json.titles.map((json: any) => new I18nString(json)) : [];

    this.links = json.links ? json.links.map((json: any) => new R20nLink(json)) : [];
    this.boxes = json.boxes ? json.boxes.map((json: any) => new R20nBox(json)) : [];

    this.userLinksUploadable = json.userLinksUploadable ? Boolean(json.userLinksUploadable) : false;

    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }

  public static getFactory(): Factory<R20nCategory> {
    return new (class implements Factory<R20nCategory> {
      make(json: any): R20nCategory {
        return new R20nCategory(json);
      }
      getTableName(): string {
        return 'residential_information_categories';
      }
    })();
  }

  static getUrl(tenantUnitId: string, categoryId?: string): string;
  static getUrl(tenantUnitId: string, categoryId: string): string {
    return (
      '/tenant_units/' + tenantUnitId + '/residential_information/categories' + (categoryId ? '/' + categoryId : '')
    );
  }
}
