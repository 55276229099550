import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { I18nString } from '../class/i18nstring';
import { HasTenantUnitId } from '../interface/has-tenant-unit-id';
import { HasEntranceId } from '../interface/has-entrance-id';

export enum Orientation {
  Landscape = 'Landscape',
  Portrait = 'Portrait',
}

export class Document {
  id: string; // NOTE: Used for deleting.
  headers: I18nString[];
  bodies: I18nString[];
  url: string;

  constructor(json: any) {
    this.id = json.id;
    this.headers = json.headers ? json.headers.map((json: any) => new I18nString(json)) : [];
    this.bodies = json.bodies ? json.bodies.map((json: any) => new I18nString(json)) : [];
    this.url = json.url;
  }
}

export class DigitalSignage implements Deletable, Identifiable, HasTenantUnitId, HasEntranceId {
  id: string;
  deleted?: boolean;
  deletedDbFlag: 0 | 1; // Only used for indexing in DB.

  tenantUnitId: string;
  entranceId: string;

  orientation: Orientation;
  greetings: I18nString[];
  descriptions: I18nString[];
  documents: Document[];

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = this.deleted ? 1 : 0;

    this.tenantUnitId = json.tenantUnitId;
    this.entranceId = json.entranceId;

    this.orientation = json.orientation as Orientation;

    this.greetings = json.greetings ? json.greetings.map((json: any) => new I18nString(json)) : [];
    this.descriptions = json.descriptions ? json.descriptions.map((json: any) => new I18nString(json)) : [];
    this.documents = json.documents ? json.documents.map((json: any) => new Document(json)) : [];

    this.created = json.created;
    this.modified = json.modified;
  }

  public static getFactory(): Factory<DigitalSignage> {
    return new (class implements Factory<DigitalSignage> {
      make(json: any): DigitalSignage {
        return new DigitalSignage(json);
      }
      getTableName(): string {
        return 'digital_signages';
      }
    })();
  }

  static getUrl(tenantUnitId: string, digitalSignageId?: string): string;
  static getUrl(tenantUnitId: string, digitalSignageId: string): string {
    return '/tenant_units/' + tenantUnitId + '/digital_signage' + (digitalSignageId ? '/' + digitalSignageId : '');
  }
}
