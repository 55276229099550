import { Factory } from '../interface/factory';
import { I18nString } from '..';

export enum StoryActionType {
  Url = 'Url',
  MailTo = 'MailTo',
}

export abstract class StoryAction {
  type: StoryActionType;
  titles: I18nString[];

  constructor(json: any, type: StoryActionType) {
    this.type = type;
    this.titles = json.titles ? json.titles.map((js: any) => new I18nString(js)) : [];
  }

  static getFactory(): Factory<StoryAction> {
    return new (class implements Factory<StoryAction> {
      make(json: any): StoryAction {
        switch (json.type) {
          case StoryActionType.Url:
            return new UrlAction(json);
          case StoryActionType.MailTo:
            return new MailToAction(json);
          default:
            throw new Error('Unrecognized StoryAction type (' + json.type + ').');
        }
      }

      getTableName(): string {
        throw new Error(
          'getTableName() should never be called on a StoryAction. StoryActions are not stored in IndexDB.'
        );
        return 'stories';
      }
    })();
  }
}

export class UrlAction extends StoryAction {
  url: string;
  constructor(json: any) {
    super(json, StoryActionType.Url);
    this.url = json.url;
  }
}

export class MailToAction extends StoryAction {
  email: string;
  subjects: I18nString[];
  bodies: I18nString[];
  constructor(json: any) {
    super(json, StoryActionType.MailTo);
    this.email = json.email;
    this.subjects = json.subjects ? json.subjects.map((jsn: any) => new I18nString(jsn)) : [];
    this.bodies = json.bodies ? json.bodies.map((jsn: any) => new I18nString(jsn)) : [];
  }
}
