import { environment } from '../../../environments/environment';
import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { HasTenantUnitId } from '../interface/has-tenant-unit-id';
import { HasCategoryId } from '../interface/has-category-id';
import { Factory } from '../interface/factory';
import { PublishStatus } from '../enum/publish-status';
import { I18nString } from './i18nstring';
import { R20nLink } from './r20n-link';
import { R20nBox } from './r20n-box';

// ResidentialInformationItem
export class R20nItem implements Deletable, Identifiable, HasTenantUnitId, HasCategoryId {
  id: string;
  deleted: boolean;
  deletedDbFlag: 0 | 1; // NOTE: Only used for indexing in browser DB.

  tenantUnitId: string;
  categoryId: string;

  status: PublishStatus;
  titles: I18nString[];
  texts: I18nString[]; // TODO: Remove when Serneke is updated.
  images: string[]; // TODO: Remove when Serneke is updated.

  links: R20nLink[];
  boxes: R20nBox[];

  userLinksUploadable: boolean;

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = json.deleted ? 1 : 0;

    this.tenantUnitId = json.tenantUnitId;
    this.categoryId = json.categoryId;

    this.status = json.status as PublishStatus;
    this.titles = json.titles ? json.titles.map((json: any) => new I18nString(json)) : [];
    this.texts = json.texts ? json.texts.map((json: any) => new I18nString(json)) : [];
    this.images = json.images ? json.images : [];

    this.links = json.links ? json.links.map((json: any) => new R20nLink(json)) : [];
    this.boxes = json.boxes ? json.boxes.map((json: any) => new R20nBox(json)) : [];

    this.userLinksUploadable = json.userLinksUploadable ? Boolean(json.userLinksUploadable) : false;

    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }

  public static getFactory(): Factory<R20nItem> {
    return new (class implements Factory<R20nItem> {
      make(json: any): R20nItem {
        return new R20nItem(json);
      }
      getTableName(): string {
        return 'residential_information_items';
      }
    })();
  }

  static getUrl(tenantUnitId: string, itemId?: string): string;
  static getUrl(tenantUnitId: string, itemId: string): string {
    return '/tenant_units/' + tenantUnitId + '/residential_information/items' + (itemId ? '/' + itemId : '');
  }

  getImageUrl(index: number = this.images.length - 1): string | undefined {
    return this.images[index] ? environment.blobUrl + '/images/' + this.images[index] : undefined;
  }
}
