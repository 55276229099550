import { environment } from '../../../environments/environment';
import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { I18nString } from '../class/i18nstring';
import { PublishStatus } from '../enum/publish-status';
import { StoryAction } from './story-action';
import { HasTenantUnitId } from '../interface/has-tenant-unit-id';

export enum StoryType {
  News = 'News',
  Offer = 'Offer',
  ResidentInfo = 'ResidentInfo',
  ImportantInformation = 'ImportantInformation',
  ExternalBookingOffer = 'ExternalBookingOffer',
}

export class Story implements Deletable, Identifiable, HasTenantUnitId {
  id: string;
  deleted: boolean;
  deletedDbFlag: 0 | 1; // NOTE: Only used for indexing in browser DB.

  type: StoryType;

  tenantUnitId: string;
  status: PublishStatus;

  titles: I18nString[];
  subtitles: I18nString[];
  texts: I18nString[];
  images: string[];

  start: Date;
  end?: Date;

  action?: StoryAction;

  created: Date;
  modified: Date;

  constructor(json: any, type: StoryType) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = json.deleted ? 1 : 0;

    this.type = type;

    this.tenantUnitId = json.tenantUnitId;

    this.status = json.status as PublishStatus;

    this.titles = json.titles ? json.titles.map((json: any) => new I18nString(json)) : [];
    this.subtitles = json.subtitles ? json.subtitles.map((json: any) => new I18nString(json)) : [];
    this.texts = json.texts ? json.texts.map((json: any) => new I18nString(json)) : [];
    this.images = json.images ? json.images : [];

    this.start = new Date(json.start);
    this.end = json.end ? new Date(json.end) : undefined;

    const storyFactory: Factory<StoryAction> = StoryAction.getFactory();
    this.action = json.action ? storyFactory.make(json.action) : undefined;

    this.created = json.created;
    this.modified = json.modified;
  }

  public static getFactory(): Factory<Story> {
    return new (class implements Factory<Story> {
      make(json: any): Story {
        switch (json.type) {
          case StoryType.News:
            return new News(json);
          case StoryType.Offer:
            return new Offer(json);
          case StoryType.ResidentInfo:
            return new ResidentInfo(json);
          case StoryType.ImportantInformation:
            return new ImportantInformation(json);
          case StoryType.ExternalBookingOffer:
            return new ExternalBookingOffer(json);
          default:
            throw new Error('Unrecognized Story type (' + json.type + ').');
        }
      }
      getTableName(): string {
        return 'stories';
      }
    })();
  }

  static getAllUrl(tenantUnitId: string): string;
  static getAllUrl(tenantUnitId: string): string {
    return '/tenant_units/' + tenantUnitId + '/stories/all';
  }

  static getUrl(tenantUnitId: string, storyId?: string): string;
  static getUrl(tenantUnitId: string, storyId: string): string {
    return '/tenant_units/' + tenantUnitId + '/stories' + (storyId ? '/' + storyId : '');
  }

  getImageUrl(index: number = 0): string | undefined {
    return this.images[index] ? environment.blobUrl + '/images/' + this.images[index] : undefined;
  }
}

export class News extends Story {
  digitalSignageIds: string[];
  constructor(json: any) {
    super(json, StoryType.News);
    this.digitalSignageIds = json.digitalSignageIds ? json.digitalSignageIds : [];
  }
}

export class Offer extends Story {
  constructor(json: any) {
    super(json, StoryType.Offer);
  }
}

export class ResidentInfo extends Story {
  constructor(json: any) {
    super(json, StoryType.ResidentInfo);
  }
}
export class ImportantInformation extends Story {
  constructor(json: any) {
    super(json, StoryType.ImportantInformation);
  }
}

export class ExternalBookingOffer extends Story {
  constructor(json: any) {
    super(json, StoryType.ExternalBookingOffer);
  }
}
