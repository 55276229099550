import { Rule } from './rule';

export class Schedule<T> {
  stack: Rule<T>[];
  default: T;

  constructor(json: any) {
    this.stack = json.stack ? json.stack.map((json: any) => new Rule<boolean>(json)) : [];
    this.default = json.default;
  }
}
