import { environment } from '../../../environments/environment';
import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { PublishStatus } from '../enum/publish-status';
import { I18nString } from '../class/i18nstring';

export class Event implements Deletable, Identifiable {
  id: string;
  deleted: boolean;

  tenantUnitId: string;
  userId: string;

  status: PublishStatus;
  titles: I18nString[];
  subtitles: I18nString[];
  texts: I18nString[];
  images: string[];

  start: Date;
  end: Date;

  attendees: string[]; // User IDs
  address?: string;

  showAsSystemUser: boolean;

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;

    this.tenantUnitId = json.tenantUnitId;
    this.userId = json.userId;

    this.status = json.status ? (json.status as PublishStatus) : PublishStatus.Published;
    this.titles = json.titles ? json.titles.map((json: any) => new I18nString(json)) : [];
    this.subtitles = json.subtitles ? json.subtitles.map((json: any) => new I18nString(json)) : [];
    this.texts = json.texts ? json.texts.map((json: any) => new I18nString(json)) : [];
    this.images = json.images ? json.images : [];

    this.start = json.start;
    this.end = json.end;

    this.attendees = json.attendees ? json.attendees : [];
    this.address = json.address ? json.address : undefined;

    this.showAsSystemUser = json.showAsSystemUser ? Boolean(json.showAsSystemUser) : false;

    this.created = json.created;
    this.modified = json.modified;
  }

  public static getFactory(): Factory<Event> {
    return new (class implements Factory<Event> {
      make(json: any): Event {
        return new Event(json);
      }
      getTableName(): string {
        return 'events';
      }
    })();
  }

  static getUrl(tenantUnitId: string, eventId?: string): string;
  static getUrl(tenantUnitId: string, eventId: string): string {
    return '/tenant_units/' + tenantUnitId + '/events' + (eventId ? '/' + eventId : '');
  }

  getImageUrl(index: number = 0): string | undefined {
    return this.images[index] ? environment.blobUrl + '/images/' + this.images[index] : undefined;
  }
}
