import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

export class NgbTime implements NgbTimeStruct {
  hour: number;
  minute: number;
  second: number;

  constructor(hour?: number, minute?: number, second?: number) {
    this.hour = hour;
    this.minute = minute;
    this.second = second;
  }
}
