import { Factory } from '../interface/factory';

export enum ConnectionStatus {
  Awaiting = 'Awaiting',
  Established = 'Established',
  Severed = 'Severed',
}

export class Credentials {
  tenantUnitId: string;
  digitalSignageId: string;
  accessToken: string;

  constructor(json: any) {
    this.tenantUnitId = json.tenantUnitId;
    this.digitalSignageId = json.digitalSignageId;
    this.accessToken = json.accessToken;
  }
}

export abstract class DigitalSignageConnection {
  status: ConnectionStatus;
  id: string;
  pk: string;
  created: Date;
  modified: Date;

  constructor(json: any, status: ConnectionStatus) {
    this.status = status;
    this.id = json.id;
    this.pk = json.pk;
    this.created = json.created;
    this.modified = json.modified;
  }

  static getFactory(): Factory<DigitalSignageConnection> {
    return new (class implements Factory<DigitalSignageConnection> {
      make(json: any): DigitalSignageConnection {
        switch (json.status) {
          case ConnectionStatus.Awaiting:
            return new AwaitingConnection(json);
          case ConnectionStatus.Established:
            return new EstablishedConnection(json);
          case ConnectionStatus.Severed:
            return new SeveredConnection(json);
          default:
            throw new Error('Unrecognized Connection type (' + json.status + ').');
        }
      }

      getTableName(): string {
        return 'digital_signage_connections';
      }
    })();
  }
}

export class AwaitingConnection extends DigitalSignageConnection {
  credentials?: Credentials;

  constructor(json: any) {
    super(json, ConnectionStatus.Awaiting);
    this.credentials = json.credentials ? new Credentials(json.credentials) : undefined;
  }
}

export class EstablishedConnection extends DigitalSignageConnection {
  digitalSignageId: string;
  tenantUnitId: string;
  accessToken: string;
  reloadAt?: Date;

  constructor(json: any) {
    super(json, ConnectionStatus.Established);
    this.digitalSignageId = json.digitalSignageId;
    this.tenantUnitId = json.tenantUnitId;
    this.accessToken = json.accessToken;
    this.reloadAt = json.reloadAt ? new Date(json.reloadAt) : undefined;
  }
}

export class SeveredConnection extends DigitalSignageConnection {
  digitalSignageId: string;
  tenantUnitId: string;

  constructor(json: any) {
    super(json, ConnectionStatus.Severed);
    this.digitalSignageId = json.digitalSignageId;
    this.tenantUnitId = json.tenantUnitId;
  }
}
