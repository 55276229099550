import { Factory } from '../interface/factory';
import { I18nString } from './i18nstring';

export enum AssetType {
  Projector = 'Projector', // Projektor
  Catering = 'Catering', // Catering
  Screen = 'Screen', // Skärm
  Whiteboard = 'Whiteboard', // Whiteboard
  VideoConferencing = 'VideoConferencing', // Telefonkonferensutrustning
  Wifi = 'Wifi', // Wifi
  SoundSystem = 'SoundSystem', // Ljudsystem
  View = 'View', // Utsikt
  Flipchart = 'Flipchart', // Blädderblock
  DisabilityAdaptation = 'DisabilityAdaptation', // Handikappsanpassning
  NaturalLight = 'NaturalLight', // Naturligt ljusinsläpp
  CoffeeMachine = 'CoffeeMachine', // Kaffemaskin
  Water = 'Water', // Vatten
  Generic = 'Generic', // Diverse
}

export class Asset {
  type: AssetType;

  constructor(json: any, type: AssetType) {
    this.type = type;
  }

  static getFactory(): Factory<Asset> {
    return new (class implements Factory<Asset> {
      make(json: any): Asset {
        switch (json.type) {
          case AssetType.Projector:
            return new ProjectorAsset(json);
          case AssetType.Catering:
            return new CateringAsset(json);
          case AssetType.Screen:
            return new ScreenAsset(json);
          case AssetType.Whiteboard:
            return new WhiteboardAsset(json);
          case AssetType.VideoConferencing:
            return new VideoConferencingAsset(json);
          case AssetType.Wifi:
            return new WifiAsset(json);
          case AssetType.SoundSystem:
            return new SoundSystemAsset(json);
          case AssetType.View:
            return new ViewAsset(json);
          case AssetType.Flipchart:
            return new FlipchartAsset(json);
          case AssetType.DisabilityAdaptation:
            return new DisabilityAdaptationAsset(json);
          case AssetType.NaturalLight:
            return new NaturalLightAsset(json);
          case AssetType.CoffeeMachine:
            return new CoffeeMachineAsset(json);
          case AssetType.Water:
            return new WaterAsset(json);
          case AssetType.Generic:
            return new GenericAsset(json);
          default:
            throw new Error('Unrecognized Asset type (' + json.type + ').');
        }
      }

      getTableName(): string {
        throw new Error(
          'getTableName() should never be called on an Asset. Assets are not stored separately in IndexDB.'
        );
        return 'assets';
      }
    })();
  }
}

export class ProjectorAsset extends Asset {
  hdmi: boolean;
  usbC: boolean;
  constructor(json: any) {
    super(json, AssetType.Projector);
    this.hdmi = Boolean(json.hdmi);
    this.usbC = Boolean(json.usbC);
  }
}

export class CateringAsset extends Asset {
  constructor(json: any) {
    super(json, AssetType.Catering);
  }
}

export class ScreenAsset extends Asset {
  hdmi: boolean;
  displayPort: boolean;
  usbC: boolean;
  chromecast: boolean;
  miracast: boolean;
  size: number; // Diagonal inches.
  constructor(json: any) {
    super(json, AssetType.Screen);
    this.hdmi = Boolean(json.hdmi);
    this.displayPort = Boolean(json.displayPort);
    this.usbC = Boolean(json.usbC);
    this.chromecast = Boolean(json.chromecast);
    this.miracast = Boolean(json.miracast);
    this.size = Number(json.size);
  }
}

export class WhiteboardAsset extends Asset {
  width: number; // Centimeters.
  height: number; // Centimeters.
  constructor(json: any) {
    super(json, AssetType.Whiteboard);
    this.width = Number(json.width);
    this.height = Number(json.height);
  }
}

export class VideoConferencingAsset extends Asset {
  constructor(json: any) {
    super(json, AssetType.VideoConferencing);
  }
}

export class WifiAsset extends Asset {
  ssid: string;
  passphrase?: string;
  constructor(json: any) {
    super(json, AssetType.Wifi);
    this.ssid = json.ssid;
    this.passphrase = json.passphrase ? json.passphrase : undefined;
  }
}

export class SoundSystemAsset extends Asset {
  usbC: boolean;
  minijack: boolean; // 3.5 mm
  bluetooth: boolean;
  constructor(json: any) {
    super(json, AssetType.SoundSystem);
    this.usbC = Boolean(json.usbC);
    this.minijack = Boolean(json.minijack);
    this.bluetooth = Boolean(json.bluetooth);
  }
}

export class ViewAsset extends Asset {
  constructor(json: any) {
    super(json, AssetType.View);
  }
}

export class FlipchartAsset extends Asset {
  constructor(json: any) {
    super(json, AssetType.Flipchart);
  }
}

export class DisabilityAdaptationAsset extends Asset {
  constructor(json: any) {
    super(json, AssetType.DisabilityAdaptation);
  }
}

export class NaturalLightAsset extends Asset {
  constructor(json: any) {
    super(json, AssetType.NaturalLight);
  }
}

export class CoffeeMachineAsset extends Asset {
  constructor(json: any) {
    super(json, AssetType.CoffeeMachine);
  }
}

export class WaterAsset extends Asset {
  constructor(json: any) {
    super(json, AssetType.Water);
  }
}

export class GenericAsset extends Asset {
  names: I18nString[];
  constructor(json: any) {
    super(json, AssetType.Generic);
    this.names = json.names.map((n: any) => new I18nString(n));
  }
}
